import throttle from 'lodash/throttle';
import { addPassiveEventListener } from './passive-event-listener-polyfill';

class StickyManager {
    constructor(element, offset = 0, onStickyCb, onNotStickyCb) {
        this.offset = offset;
        this.element = element;
        this.onStickyCb = onStickyCb;
        this.onNotStickyCb = onNotStickyCb;
        this._onWindowThrottledScroll = throttle(this._onWindowScroll, 100);
        this.elementOriginalOffset = Math.floor(this.element.offsetTop);
        this._init();
    }

    _init() {
        this._onWindowScroll();
        this._addScrollListener();
    }

    _addScrollListener() {
        addPassiveEventListener(window, 'scroll', this._onWindowThrottledScroll);
    }

    _onWindowScroll = () => {
        const windowScrollTop = window.pageYOffset + this.offset;
        if (windowScrollTop > this.elementOriginalOffset) {
            this.onStickyCb();
        } else {
            this.onNotStickyCb();
        }
    };
}

export default StickyManager;
