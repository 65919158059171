import { dcFactory } from '@deleteagency/dc';
import modalService from '@deleteagency/modal/src/modal';
import { pageLocker } from '@deleteagency/page-locker';
import focusLock from 'dom-focus-lock';
import { eventBus } from './events';
import overlay from './overlay/default-overlay';

export const MODAL_WINDOW_OPEN = 'modal_window_open';
export const MODAL_WINDOW_CLOSE = 'modal_window_close';

modalService.init({
    onModalInit: (_modal) => {
        dcFactory.init(_modal.element, true);
    },
    onModalDestroy: (_modal) => {
        dcFactory.destroy(_modal.element);
    },
    onBeforeFirstModalOpen: (_modal) => {
        setTimeout(() => focusLock.on(_modal.element), 100);
        pageLocker.lock('modal');
        document.documentElement.classList.add('modal-opened');
        overlay.show('modal', _modal);
        eventBus.emit(MODAL_WINDOW_OPEN);
    },
    onAfterLastModalClose: (_modal) => {
        focusLock.off(_modal);
        pageLocker.unlock('modal');
        overlay.hide('modal');
        document.documentElement.classList.remove('modal-opened');
        eventBus.emit(MODAL_WINDOW_CLOSE);
    },
    defaultModalTemplate: ({ cssModifier = '', cross = true } = {}) => `
        <div class="modal${cssModifier ? ' modal--' + cssModifier : ''}" data-modal aria-hidden="true" role="dialog">
            <div class="modal__wrapper" data-modal-wrapper>
                ${cross ? (`
                    <button class="modal__close" data-modal-close type="button" aria-label="close popup">
                        <span class="button-content">
                            <svg class="icon modal__close-icon modal__close-icon--cross" width="13" height="13" focusable="false">
                                <use xlink:href="#icon-cross"/>
                            </svg>
                            <svg class="icon modal__close-icon modal__close-icon--circle" width="30" height="30" focusable="false">
                                <use xlink:href="#icon-close-circle"/>
                            </svg>
                        </span>
                    </button>
                `) : ''}
                <div class="modal__content" data-modal-content></div>
            </div>
        </div>`
});

export { modalService };

