import ArrayHelper from 'general/js/array-helper';
import sum from 'hash-sum';
import loFindIndex from 'lodash/findIndex';

const RECENTLY_SEARCHED_PROPERTIES_KEY = 'winkworth_recently_searched_properties_key';
const MAX_ITEMS_TO_STORE = 10;

class PropertyStorageService {
    updateStore(data) {
        if (!this.isKeyExists()) {
            this.setStore([this.withHash(data)]);
        } else {
            let storage = this.getStore().slice(0);

            const newItem = this.withHash(data);

            if (ArrayHelper.isEmpty(newItem.propertyCards)) return;

            // check if it's the same search
            if (this.isTheSame(storage, newItem.hash)) {
                const existingSearchIndex = loFindIndex(
                    storage,
                    (search) => search.hash === newItem.hash
                );

                if (existingSearchIndex !== storage.length - 1) {
                    ArrayHelper.arrayMove(storage, existingSearchIndex, storage.length - 1);
                }
            } else {
                storage.push(newItem);
            }

            // restrict storage to max items
            if (storage.length > MAX_ITEMS_TO_STORE) {
                storage = storage.slice(-MAX_ITEMS_TO_STORE);
            }

            this.setStore(storage);
        }
    }

    isTheSame = (storage, hash) => storage.some((search) => search.hash === hash);

    withHash = (object) =>
        Object.assign({}, object, {
            hash: sum(Object.assign({}, object.propertySearchFormModel, object.pagination)),
        });

    isKeyExists = () => localStorage.getItem(RECENTLY_SEARCHED_PROPERTIES_KEY) !== null;

    getStore = () => JSON.parse(localStorage.getItem(RECENTLY_SEARCHED_PROPERTIES_KEY));

    setStore = (params) => {
        try {
            localStorage.setItem(RECENTLY_SEARCHED_PROPERTIES_KEY, JSON.stringify(params));
        } catch (e) {
            console.warn(`localStorage write error: ${e} `);
        }
    };
}

export const propertyStorageService = new PropertyStorageService();
