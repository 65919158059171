import isEqualLodash from 'lodash/isEqual';
import filter from 'lodash/filter';
import camelCase from 'lodash/camelCase';
import matches from 'lodash/matches';

function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

function isEqual(arg1, arg2) {
    return isEqualLodash(arg1, arg2);
}

function isObject(value) {
    return typeof value === 'object';
}

function isPartialEqual(arr, obj) {
    return filter(arr, matches(obj));
}

function renameKey(obj, oldKey, newKey) {
    if (obj[oldKey] && oldKey !== newKey) {
        Object.defineProperty(obj, newKey,
            Object.getOwnPropertyDescriptor(obj, oldKey));
        delete obj[oldKey];
        return true;
    }
    return false;
}

function convertObjectsKeysToCamelCase(obj) {
    Object.keys(obj).forEach((key) => {
        renameKey(obj, key, camelCase(key));
    });
}

/**
 * This method parse names of the every collection entry and if the name is like "name[]"
 * we will build an array in the collection, if the name is "name[test]" we will add name as object
 *
 * @param collection
 * @param name
 * @param value
 */
function addToAssociativeCollection(collection, name, value) {
    const arrayRegexp = /(.+)\[(.*)\]/;
    const arrayParseResult = name.match(arrayRegexp);
    if (arrayParseResult !== null) {
        name = arrayParseResult[1];
        const key = arrayParseResult[2];
        const isObj = key !== '' && !/^\d+$/.test(key);
        if (!(name in collection)) {
            collection[name] = isObj ? {} : [];
        }
        if (isObj) {
            collection[name][key] = value;
        } else {
            collection[name].push(value);
        }
    } else {
        collection[name] = value;
    }
}

export {
    isEqual,
    isObject,
    addToAssociativeCollection,
    renameKey,
    clone,
    isPartialEqual,
    convertObjectsKeysToCamelCase
};
