import { IdleQueue } from 'idlize/IdleQueue.mjs';

class AnalyticsService {
    constructor() {
        this.queue = new IdleQueue({ ensureTasksRun: true });
    }

    _send(event, data) {
        this.queue.pushTask(() => {
            window.dataLayer.push({ event, ...data });
        });
    }

    sendEvent(category, action, label) {
        this._send('eventTrack', {
            eventCategory: category,
            eventAction: action,
            eventLabel: label
        });
    }

    sendVirtualUrl(value) {
        this._send('pageviewTrack', { virtualPage: value });
    }
}

export default new AnalyticsService();
