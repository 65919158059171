import { DcBaseComponent } from "@deleteagency/dc";

export default class AnimatedOfficeHeroComponent extends DcBaseComponent {
    _interval = this.options.interval * 1000 || 8000;
    _prevRemoveInterval = 2000;
    _slideCount = 1;
    _activeSlide = 0;
    _activeSlideEl = null;

    _classes = {
        active: 'animated-office-hero__slide--active',
        prev: 'animated-office-hero__slide--prev'
    };

    static getNamespace() {
        return 'animated-office-hero';
    }

    onInit() {
        const slides = [...this.element.querySelectorAll('[data-animated-office-hero-slide]')];
        this._slideCount = slides.length;
        if (this._slideCount > 1) {
            this.setActiveSlideEl(this._activeSlide);
            this.startCountToNextSlide();
        }

    }

    setActiveSlideEl(int) {
        this._activeSlideEl = this.element.querySelector(`[data-animated-office-hero-slide="${int}"]`);
    }

    startCountToNextSlide() {

        let interval = this._interval;
        if (window.innerWidth < 768) {
            interval = interval / 1.5;
        }

        setTimeout(this.changeSlide.bind(this), interval);

    }

    changeSlide() {

        let prevRemoveInterval = this._prevRemoveInterval;
        if (window.innerWidth < 768) {
            prevRemoveInterval = prevRemoveInterval / 1.5;
        }
        
        const prevActiveSlideEl = this._activeSlideEl;

        if (this._activeSlide === this._slideCount - 1) {
            // last slide currently active
            this._activeSlide = 0;
        } else {
            this._activeSlide ++;
        }

        this.setActiveSlideEl(this._activeSlide);

        prevActiveSlideEl.classList.add(this._classes.prev);

        setTimeout(() => {
            this._activeSlideEl.classList.add(this._classes.active);
            prevActiveSlideEl.classList.remove(this._classes.prev)
            prevActiveSlideEl.classList.remove(this._classes.active);
        }, prevRemoveInterval);

        this.startCountToNextSlide();
    }
}
