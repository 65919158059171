import app from 'general/js/app';

let isLoaded = false;
let isLoading = false;
const onloadQueue = [];

window.onGoogleMapsIframeAPIReady = function () {
    isLoaded = true;
    onloadQueue.forEach((callback) => callback());
};

function insertScript() {
    const apiKey = app.getConfig('GOOGLE_MAPS_API_KEY');
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?${
        apiKey ? `key=${apiKey}` : ''
    }&callback=onGoogleMapsIframeAPIReady`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
}

function onReady(callback) {
    if (!isLoading) {
        insertScript();
        isLoading = true;
    }
    if (isLoaded) {
        callback();
    } else {
        onloadQueue.push(callback);
    }
}

export default onReady;
