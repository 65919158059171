import './scss/index.scss';
import { dcFactory } from '@deleteagency/dc';
import AnimatedOfficeHeroComponent from './js/animated-office-hero.component';

import { TabbedSearch } from './js/tabbed-search.component';

import { PopularLocations} from './../popular-locations/js/popular-locations.component';

dcFactory.register(AnimatedOfficeHeroComponent);
dcFactory.register(TabbedSearch);
dcFactory.register(PopularLocations);