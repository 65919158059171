// @ts-nocheck
import mapPinProperty from 'general/img/icons/map-pin-new_outside.svg';
import mapPinViewed from 'general/img/icons/map-pin-key_viewed.svg';
import mapPinSelected from 'general/img/icons/map-pin-key_selected.svg';

import { BaseMarker } from '../index';

export default class OutsidePropertyMarker extends BaseMarker {
    constructor(options) {
        super(options);
    }
    
    getIconUrl(mapPin = mapPinProperty) {
           
        const viewedProperties = sessionStorage.getItem('winkworth_search_results_map__viewed_properties');
        
        if (viewedProperties) {
            this._isViewed = this._isMarkerViewed(this.config.locationId);
            mapPin = mapPinProperty;
            if (this._isActive) {
                mapPin = mapPinSelected;
            } else if (this._isViewed) {
                mapPin = mapPinViewed;
            }            
        } else {            
            if (this._isActive) {
                mapPin = mapPinSelected;
            }
        }
        return mapPin;
    }

    getIconWidth() {
        return this.width || 24;
    }
    
    getIconHeight() {
        return this.height || 24;        
    }
}
