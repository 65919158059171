import anime from 'animejs';

class Scroller {
    constructor(element, options = {}) {
        this.element = element;

        this.defaultOptions = {
            scrollOffset: 0,
            duration: 1000
        };

        this.options = { ...this.defaultOptions, ...options };
    }

    getElementDocumentPosition() {
        const box = this.element.getBoundingClientRect();

        return {
            top: box.top + window.pageYOffset,
            bottom: box.bottom + window.pageYOffset
        };
    }

    _scrollTo(coordinate) {
        const topCoordinate = coordinate - this.options.scrollOffset;

        const documentAnimation = anime({
            targets: 'body, html ',
            scrollTop: topCoordinate,
            duration: this.options.duration,
            easing: 'easeInOutSine'
        });

        return documentAnimation.finished.then(() => new Promise((resolve) => {
            // scroller resolve end of animation before
            // it actually settles in Safari, IE and Edge
            setTimeout(() => {
                resolve();
            }, 0);
        }));
    }

    scrollToTop(safe = false) {
        const topCoordinate = this.getElementDocumentPosition().top;
        if (safe && this.isCoordinateWithin(topCoordinate)) {
            return Promise.resolve();
        }
        return this._scrollTo(topCoordinate);
    }

    scrollToBottom() {
        const bottomCoordinate = this.getElementDocumentPosition(this.element).bottom;
        return this._scrollTo(bottomCoordinate);
    }

    shortestScrollTo() {
        const { top, bottom } = this.getElementDocumentPosition();

        if (this.isCoordinateWithin(top) && this.isCoordinateWithin(bottom)) {
            // if both top and bottom are visible - do not do anything
            return Promise.resolve();
        }

        if (this.isCoordinateAbove(top)) {
            // scroll up to top edge
            return this._scrollTo(top);
        }
        // scroll down to bottom edge
        return this._scrollTo(this.getViewportTop() + (bottom - this.getViewportBottom()));
    }

    isCoordinateWithin(coordinate) {
        return this.getViewportTop() <= coordinate && this.getViewportBottom() >= coordinate;
    }

    getViewportTop() {
        return window.pageYOffset;
    }

    getViewportBottom() {
        return this.getViewportTop() + this.getViewportHeight();
    }

    isCoordinateAbove(coordinate) {
        return this.getViewportTop() >= coordinate;
    }

    getViewportHeight() {
        return document.documentElement.clientHeight;
    }
}

export default Scroller;
