import { DcBaseComponent } from "@deleteagency/dc";

export class PopularLocations extends DcBaseComponent {

  static getNamespace() {
      return 'popular-locations';
  }

  onInit() {
    const searchInputWrapper = $('.updated-quick-search__input-wrapper');
    const searchInput = $('.search__input-text');
    const popLocations = $('.popular-locations');
    const popLocationsList = $('.popular-locations__list');

    // create list from local storage
    const popularLocations = JSON.parse(localStorage.getItem('winkworth_recently_searched_locations')) || [];
    if (popularLocations.length > 0) {
        popLocationsList.empty();
        popularLocations.sort((a, b) => b.timestamp - a.timestamp);
        const latestFourLocations = popularLocations.slice(0, 4);
        latestFourLocations.forEach(location => {
            popLocationsList.append(`<li class="popular-locations__list-item" data-location-name="${location.location}">${location.location}</li>`);
        });
    }

    popLocationsList.on('click', '.popular-locations__list-item', function() {
      const setText = $(this).text();
      
      const searchInputs = $('.search__input-text');

      searchInputs.each(function() {
          $(this).val(setText);
          $(this).attr('data-location', 'valid');
      });
  
      let allInputsValid = true;
      searchInputs.each(function() {
          if ($(this).val().length < 3) {
              allInputsValid = false;
              return false;
          }
      });
  
      const modalSubmitBtn = $('.search-results-all-filters-modal__form button[type="submit"]');
      const isMobile = window.innerWidth < 768;
      if (allInputsValid && isMobile) {
        setTimeout(() => {
          modalSubmitBtn.trigger('click');
        }, 1000);
      }

      popLocations.removeClass('active');
  });
  
    searchInput.on('focus', () => {
      searchInputWrapper.addClass('active');            
      if (searchInput.val() == '') {
        popLocations.addClass('active');
      }
    });
      
    searchInput.on("change paste keyup", () => {
      if (searchInput.is(':focus') || searchInput.val() !== '') {
        searchInputWrapper.addClass('active');                
      }
      if (searchInput.val() !== '') {
        searchInputWrapper.removeClass('active');
        popLocations.removeClass('active');
      }
      if (searchInput.val() === '') {
        searchInputWrapper.addClass('active');
        popLocations.addClass('active');
      }
    });

    let popLocationsClicked = false;

    popLocations.on('click', () => { popLocationsClicked = true; });
          
    $('body').on('mouseup', function (e) {
      if (!searchInput.is(e.target) && searchInput.has(e.target).length === 0 && !popLocations.is(e.target) && popLocations.has(e.target).length === 0) {
        popLocations.removeClass('active');
      }
      popLocationsClicked = false;
    });
  }
}