// @ts-nocheck
import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';

import api from 'general/js/api';
import { GoogleMarker } from 'general/js/google-maps';

import mapPinProperty from 'general/img/icons/map-pin-key_property.svg';
import mapPinViewed from 'general/img/icons/map-pin-key_viewed.svg';
import mapPinSelected from 'general/img/icons/map-pin-key_selected.svg';
import mapPinActive from 'general/img/icons/map-pin-active.svg';
import mapHouse from 'general/img/icons/map-pin.svg';

export default class BaseMarker extends GoogleMarker {
    // Constants
    static SEARCH_MAP_SELECTOR = '#property-search-map';

    static ICON_DEFAULT_WIDTH = 44;
    static ICON_DEFAULT_HEIGHT = 55;
    static ICON_SEARCH_WIDTH = 24;
    static ICON_SEARCH_HEIGHT = 24;

    constructor(options) {
        super(options);
        this._popupElement = this.config.popupElement;
        if (this._popupElement) {
            this._onStateChangeCallback = this._onPopupStateChange;
            this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);
        }
    }

    _onPopupStateChange = async (activated) => {
        if (!activated) return;

        if (!this._isSearchMap()) {
            const popupImage = this._popupElement.querySelector('[data-popup-image]');
            if (popupImage && !popupImage.dataset.srcset) {
                const { urlToRequestImageSrcSet } = this.config;
                if (urlToRequestImageSrcSet === 'null') return;
                const imageSrcSet = await this._getImageSrcSet(urlToRequestImageSrcSet);
                if (imageSrcSet) {
                    popupImage.dataset.srcset = imageSrcSet;
                    // @ts-ignore
                    if (window.lazySizes) window.lazySizes.loader.unveil(popupImage);
                }
            }
        }
    };

    _getImageSrcSet = (url) => {
        return this.axiosSingular.get(url).then(
            (response) => response.data,
            (error) => {
                if (!axios.isCancel(error)) {
                    console.error('Actual error!', error);
                }
            }
        );
    };

    getIconUrl() {
        if (!this._isSearchMap()) {
            if (this._isActive) {
                return mapPinActive;
            } else {
                return mapHouse;
            }
        } else {
            const viewedProperties = sessionStorage.getItem(
                'winkworth_search_results_map__viewed_properties'
            );
            if (viewedProperties) {
                this._isViewed = this._isMarkerViewed(this.config.locationId);
                let mapPin = mapPinProperty;
                if (this._isActive) {
                    mapPin = mapPinSelected;
                } else if (this._isViewed) {
                    mapPin = mapPinViewed;
                }
                return mapPin;
            } else {
                if (this._isActive) {
                    return mapPinSelected;
                } else {
                    return mapPinProperty;
                }
            }
        }
    }

    getIconWidth() {
        return this._isSearchMap() ? BaseMarker.ICON_SEARCH_WIDTH : BaseMarker.ICON_DEFAULT_WIDTH;
    }

    getIconHeight() {
        return this._isSearchMap() ? BaseMarker.ICON_SEARCH_HEIGHT : BaseMarker.ICON_DEFAULT_HEIGHT;
    }

    _isSearchMap() {
        return document.querySelector(BaseMarker.SEARCH_MAP_SELECTOR) !== null;
    }

    _isMarkerViewed(markerId) {
        const propertyId = markerId;
        const viewedProperties = sessionStorage.getItem(
            'winkworth_search_results_map__viewed_properties'
        );
        if (!viewedProperties) return false;
        const viewedList = JSON.parse(viewedProperties);
        return viewedList.some((prop) => prop.propertyId === String(propertyId));
    }
}
