import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';
import Flickity from 'flickity';
import constants from 'general/js/constants';

export default class PropListCarouselComponent extends DcBaseComponent {
    constructor(element) {
        super(element);
    }

    static getNamespace() {
        return 'prop-panel-car';
    }

    onInit() {
        this._carousel = null;
        this._flickityOptions = {
            contain: true,
            wrapAround: true,
            pageDots: false,
            watchCSS: true,
            prevNextButtons: true,
            arrowShape: constants.CAROUSEL_ARROW_SHAPE
        };
        this._initFlickity();

        deviceObserver.subscribeOnChange(() => {
            this._initFlickity();
        });
    }

    _initFlickity() {
        this._initDesktopFlickity();
        if (deviceObserver.is('<=', 'mobile')) {
            this._flickityOptions.pageDots = true;
            this._flickityOptions.prevNextButtons = false;
        }
        this._carousel = new Flickity(this.element, this._flickityOptions);
    }

    _initDesktopFlickity() {
        const childrenCount = this.element.children.length;
        if (childrenCount > 3) {
            this.element.classList.add('flick-car--desktop-only');
        }
    }

    onDestroy() {
        this._carousel.destroy();
        this._carousel = null;
    }
}
