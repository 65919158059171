// @ts-nocheck
import mapPinActive from 'general/img/icons/map-pin-winkworth-active.svg';
import mapPin from 'general/img/icons/map-pin-winkworth.svg';
import { BaseMarker } from '../index';

export default class WinkworthLogoMarker extends BaseMarker {
    constructor(options) {
        super(options);
    }

    getIconUrl() {
        return this._isActive ? mapPinActive : mapPin;
    }

    getIconWidth() {
        return this.width || 44;
    }

    getIconHeight() {
        return this.height || 55;
    }
}
