function supportsPassive() {
    // Test via a getter in the options object to see if the passive property is accessed
    let supportsPassive = false;
    try {
        const opts = Object.defineProperty({}, 'passive', {
            get() {
                supportsPassive = true;
            }
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
    return supportsPassive;
}
const addPassiveEventListener = function (element, eventName, eventCallback) {
    // Use our detect's results. passive applied if supported, capture will be false either way.
    element.addEventListener(
        eventName,
        eventCallback,
        supportsPassive() ? { passive: true } : false
    );
};
const removePassiveEventListener = function (element, eventName, eventCallback) {
    // Use our detect's results. passive applied if supported, capture will be false either way.
    element.removeEventListener(
        eventName,
        eventCallback,
        supportsPassive() ? { passive: true } : false
    );
};
export default supportsPassive;
export { addPassiveEventListener, removePassiveEventListener };
