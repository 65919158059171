import { DcBaseComponent } from '@deleteagency/dc';
import onMapsReady from './load-google-maps-script';

export default class StreetViewComponent extends DcBaseComponent {
    static getNamespace() {
        return 'street-view';
    }

    onInit() {
        onMapsReady(this._initPanorama);
    }

    _initPanorama = () => {
        this.panorama = new google.maps.StreetViewPanorama(
            this.element,
            {
                position: this.options.position,
                pov: {
                    heading: 34,
                    pitch: 10
                }
            }
        );
    }
}
