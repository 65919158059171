import { DcBaseComponent } from '@deleteagency/dc';
import { GoogleMap } from 'general/js/google-maps';
import BaseMarker from './base-marker';
import WinkworthLogoMarker from './winkworth-logo-marker';
import onMapsReady from './load-google-maps-script';
import { winkworthTheme } from './winkworth-theme';

const DEFAULT_ZOOM = 14;
// streets-buildings
const MAX_ZOOM = 19;
// continents level
const MIN_ZOOM = 1;

export default class MapComponent extends DcBaseComponent {
    static getNamespace() {
        return 'map';
    }

    _getDefaultMapOptions() {
        return {
            zoom: DEFAULT_ZOOM,
            maxZoom: MAX_ZOOM,
            minZoom: MIN_ZOOM,
        };
    }

    onInit() {
        this.defaultMarkerOptions = {};
        const { markers, ...options } = this.options;
        this.markers = markers || [];
        this.mapOptions = { ...this._getDefaultMapOptions(), ...options };

        if (this.mapOptions.styles === "dark") {
            this.mapOptions.styles = winkworthTheme;
        }

        this.map = new GoogleMap(this.element, this.mapOptions, {
            initPromise: () => new Promise((resolve) => onMapsReady(resolve)),
            createMarker: (opts) =>
                {
                    if (opts.config && opts.config.propertyId) {
                        opts.config.popupElement = document.getElementById('marker-' + opts.config.propertyId);
                    }

                    if (this.options.useLogoMarker) {
                        return new WinkworthLogoMarker({
                            ...opts,
                            width: this.options.markerWidth,
                            height: this.options.markerHeight
                        })
                    } else {
                        return new BaseMarker({
                            ...opts,
                            width: this.options.markerWidth,
                            height: this.options.markerHeight
                        })
                    }
            },
            ... (this.options.useLogoMarker && {fitPaddings: {top: 20, left: 20, bottom: 20, right: 20}})
        });
        this.map.setMarkers(
            this.markers.map((marker) => ({ ...this.defaultMarkerOptions, ...marker }))
        );
        this.map.render();
    }
}
