import { viewports, defaultScrollBarSize } from '../../config';

const VIEWPORT_WIDTH_MOBILE = viewports.mobile;
const VIEWPORT_WIDTH_TABLET = viewports.tablet;
const VIEWPORT_WIDTH_DESKTOP = viewports.desktop;
const CAROUSEL_ARROW_SHAPE = 'M22.5,58.3l38,38.2c4.7,4.7,12.2,4.7,16.9,0s4.7-12.2,0-16.9L47.7,50l29.7-29.7c4.6-4.7,4.6-12.2,0-16.8s-12.2-4.6-16.8,0l-38,38C18,46.2,18,53.6,22.5,58.3z';
const DEFAULT_SCROLLBAR_SIZE = defaultScrollBarSize;

export default {
    VIEWPORT_WIDTH_MOBILE,
    VIEWPORT_WIDTH_TABLET,
    VIEWPORT_WIDTH_DESKTOP,
    CAROUSEL_ARROW_SHAPE,
    DEFAULT_SCROLLBAR_SIZE
};
