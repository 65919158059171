const viewports = {
    mobile: 700,
    tablet: 1024,
    propcarouselbreak: 1200,
    desktop: 1172,
    laptop: 1366,
};
const defaultTransitionDurationMovement = 300;
const defaultTransitionDurationHover = 300;
const defaultScrollBarSize = 17;

module.exports = {
    viewports,
    defaultTransitionDurationMovement,
    defaultTransitionDurationHover,
    defaultScrollBarSize,
};
