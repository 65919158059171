import { dcFactory } from '@deleteagency/dc';
import { createElement } from '@deleteagency/dom-helper';
import GoogleDrawing from "./google-drawing";

export default class GoogleMarker extends GoogleDrawing {
    constructor({ id, map, mapInstance, config, options }) {
        super({ mapInstance, config });

        this._id = id;
        this._map = map;
        this._onStateChangeCallback = null;
        this._isActive = false;
        this._isViewed;
        this._popupElement = null;
        this._linkUrl = null;

        this.config = config;
        this.options = options;

        if (this.config.popupElement) {
            this._popupElement = this.config.popupElement;
        }

        if (this.config.url) {
            this._linkUrl = this.config.url;
        }

        if (this.config.locationData) {
            this._location = this.config.locationData;
        }

        this._onClick = this._onClick.bind(this);

        this._clickListener = this._instance.addListener('click', () => {
            if (document.querySelector('#property-search-map')) {            
                this.setupTemplate(this._location);
            }
            this._onClick();
        });

    }

    getNativeClass() {
        return google.maps.Marker;
    }

    getBounds() {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this._instance.getPosition());
        return bounds;
    }

    _getGoogleOptions() {
        return {
            ...this.config,
            map: this._mapInstance,
            icon: this._getGoogleIcon(),
        };
    }

    _getGoogleIcon() {
        const iconUrl = this.getIconUrl();
        if (iconUrl) {
            return {
                url: iconUrl,
                scaledSize: new google.maps.Size(this.getIconWidth(), this.getIconHeight())
            }
        }

        return null;
    }

    getPosition() {
        return this._instance.getPosition();
    }

    setPopupElement(element) {
        this._popupElement = element;
    }

    activate() {
        if (!this._isActive) {
            this._activate();
        }
    }

    deactivate() {
        if (this._isActive) {
            this._deactivate();
        }
    }

    onStateChange(cb) {
        this._onStateChangeCallback = cb;
        return () => {
            this._onStateChangeCallback = null;
        };
    }

    _onClick() {
        this._activate();
    };

    _activate() {
        this._map.onMarkerActivate(this._id);
    }

    handleActivate() {
        if (!this._isActive) {
            this._handleActivate();
        }

        if (this.options.autoFitPopup) {
            this._fitPopup();
        }
    }

    _handleActivate() {        
        this._isActive = true;
        this._renderIcon();            
        this._showPopup();
        
        const searchPropertiesMap = document.querySelector('#property-search-map');
        if (searchPropertiesMap) {
            this._addViewedProperty(this._instance);
        }
        
        // if there's a link, open in new tab/window
        if (this._linkUrl) {
            window.open(this._linkUrl, '_blank').focus();
        }
    }

    _addViewedProperty(property) {
        this._isViewed = true;
        
        if (!sessionStorage.getItem('winkworth_search_results_map__viewed_properties')) {
            sessionStorage.setItem('winkworth_search_results_map__viewed_properties', JSON.stringify([]));
        }
    
        const localViewed = JSON.parse(sessionStorage.getItem('winkworth_search_results_map__viewed_properties'));
    
        if (!localViewed.some(prop => prop.propertyId === property.locationData.externalID)) {
            localViewed.push({ propertyId: property.locationData.externalID });    
            sessionStorage.setItem('winkworth_search_results_map__viewed_properties', JSON.stringify(localViewed));
        }
    }
    

    _deactivate() {
        this._map.onMarkerDeactivate(this._id);
    }

    handleDeactivate() {
        if (this._isActive) {
            this._handleDeactivate();
        }
    }

    _handleDeactivate() {
        this._isActive = false;
        this._hidePopup();
        this._renderIcon();
        this.invokeOnStateChange();
    }

    _renderIcon() {
        this._instance.setIcon(this._getGoogleIcon());
    }

    _reset() {
        if (this._isActive) {
            this._deactivate();
        }
    }

    destroy() {
        this._reset();
        this._clickListener.remove();
        this._instance.setMap(null);
        this._instance = null;
        this._onStateChangeCallback = null;
    }

    invokeOnStateChange() {
        if (this._onStateChangeCallback) {
            this._onStateChangeCallback(this._isActive, this._isViewed);
        }
    }

    getIconWidth() {
        return 10;
    }

    getIconHeight() {
        return 10;
    }

    getIconUrl() {
        return null;
    }

    _showPopup() {
        const searchPropertiesMap = document.querySelector('#property-search-map');

        if (!searchPropertiesMap) {
            if (this._popupElement) {
                const PopupClass = this.getPopupClass();
                this.popup = new PopupClass(this.getPosition(), this._popupElement);
                this.popup.setMap(this._mapInstance);
            }
            return;
        }
        
        const popupContainer = document.querySelector('.map__marker-popups');

        // New Search Property Popup - Set the popup content and populate content
        const popupTarget = document.querySelector(".popup-container");
        const mapContainer = document.querySelector("#map");
        popupTarget.innerHTML = popupContainer.innerHTML;
        
        if (popupTarget.innerHTML !== "") {
            mapContainer.classList.add('show');
        }

        // Re-initialise Keen Slider Carousel
        reInitKeenSlider(popupTarget);
        // Re-initialise DC Factory
        dcFactory.init(popupTarget);

        // Trigger the expand trigger for the property popup
        const expandPopupTrigger = popupTarget.querySelector('.map-expand-trigger');
        const closePopupTrigger = popupTarget.querySelector('.map-popup-close');
        
        closePopupTrigger.addEventListener('click', () => {
            const map = document.querySelector('#map');
            map.classList.remove('mobile-popup-open');
            this._reset();
        });

        if (expandPopupTrigger) {            
            expandPopupTrigger.addEventListener('click', function() {
                const popup = this.closest('.popup-container');
                const map = document.querySelector('#map');
                map.classList.add('mobile-popup-open');
                const large = popup.querySelector('.mobile_expanded');
                const small = popup.querySelector('.mobile_small');
                large.classList.add('show')
                large.classList.remove('hide');
                small.classList.add('hide');
                small.classList.remove('show');            
                reInitKeenSlider(popup);
                dcFactory.init(popup);
            });
        }
    }

    _fitPopup() {
        if (this.popup) {
            setTimeout(() => {
                const { getPopupContent, fitPopupPaddings } = this.options;

                let targetElement = this._popupElement;
                if (getPopupContent) {
                    targetElement = getPopupContent(this._popupElement);
                }

                if (!targetElement.clientWidth === 0 || targetElement.clientHeight === 0) {
                    console.warn('Width or height of your popup is 0 which is not correct. ' +
                        'If the root of your popup have zero dimensions on purpose please provide GoogleMapOptions.getPopupContent ' +
                        'which should return an actual content element with non-zero width and height')
                }

                const halfWidth = parseInt(targetElement.clientWidth / 2);
                const verticalDiff = targetElement.parentElement.getBoundingClientRect().top - targetElement.getBoundingClientRect().top;

                this._mapInstance.panToBounds(this.getBounds(), {
                    top: fitPopupPaddings.top + verticalDiff,
                    bottom: fitPopupPaddings.bottom,
                    left: fitPopupPaddings.left + halfWidth,
                    right: fitPopupPaddings.right + halfWidth,
                });
            }, 100);
        }
    }

    _hidePopup() {
        const searchPropertiesMap = document.querySelector('#property-search-map');
        if (!searchPropertiesMap) {
            if (this.popup) {
                this.popup.remove();
            }
            return;
        }

        // New Search Property Popup - Clear the popup content
        const popupTarget = document.querySelector(".popup-container");
        const mapContainer = document.querySelector("#map");

        if (popupTarget.innerHTML === ""){
            popupTarget.classList.remove('show');
            return;
        }           
        popupTarget.innerHTML = "";
        mapContainer.classList.remove('show');
    }

    getPopupClass() {
        return getPopupClass();
    }

    // Function to render the HTML out in correct place for the template to render
    setupTemplate(location) {
        const searchPropertiesMap = document.querySelector('#property-search-map');
        if (!searchPropertiesMap) {
            return;
        }

        this.popupContainer = document.querySelector('.map__marker-popups');
        if (!this.popupContainer) {
            return;
        }
        this.popupContainer.innerHTML = '';
        const popup = createElement(this.popupTemplate(location));
        this.popupContainer.appendChild(popup);
        dcFactory.init(this.popupContainer);
    }
    popupTemplate (location) {
        let virtualTours = '';
        let propertyStatus = '';
        let IsShortLet = '';
        
        let gallery = '';
        let imageContainer = ''
        let galleryImages = location.allImagesSrcSet;
        let mainImage = location.mainImageUrl;

        let propertyType = location.propertyType !== null ? location.propertyType : 'house';
        let propertyTypeString = '';
        
        let bedrooms = location.bedrooms !== 0 ? location.bedrooms : 6;
        let bathrooms = location.bathrooms !== 0 ? location.bathrooms : 4;
        let receptionRooms = location.receptionRooms !== 0 ? location.receptionRooms : 3;
        
        let officeName = location.office !== null ? location.office.name : 'Test';
        let officeNumber = location.office !== null ? location.propertyChannel === 'Sales' ? location.office.salesOfficeNumber : location.office.lettingsOfficeNumber : '020 7355 0220';
        let officeUrl = location.office !== null ? location.office.officeUrl : 'https://www.winkworth.co.uk/estate-agents/test';

        let seeMoreCTAText = location.seeMoreCTAText !== null ? location.seeMoreCTAText : 'Tap for more information';
        
        
        if (location.virtualTours !== null ){
            if (location.virtualTours.some(x => x.type === 'EmbeddedVirtualTour' || x.type === 'VirtualTour' || x.type === 'External')) {
                virtualTours  = `<p class="info-banner tour">Virtual tour</p>`
            } else if (location.virtualTours.some(x => x.type === 'VideoWalkthrough')) {
                virtualTours = `<p class="info-banner tour">Video tour</p>`
            }
        }
        
        if (location.propertyStatus !== 'Available') {
            propertyStatus = `<p class="info-banner status" data-status="${location.propertyStatus}">${location.propertyStatus}</p>`
        }
        
        if (location.isShortLet) {
            IsShortLet = `<p class="info-banner short-let">${location.cardShortLetString}</p>`
        }   
    
        if (galleryImages !== null) {
    
            if ( galleryImages.length > 0 ){
                
                gallery = galleryImages.map((propertyImage) => {

                    const propertyMediaImageUrl = propertyImage.split('?')[0];
                    const fallbackSrc = `${propertyMediaImageUrl}?width=320`;
    
                    if (propertyImage === galleryImages[0] || propertyImage === galleryImages[1]) {
                        return `
                            <div class="slider-banner-image keen-slider__slide">
                                <a href="${location.propertyUrl}">
                                    <div class="slider-banner-image-img">
                                        <img 
                                            width="317"
                                            height="200"
                                            src="${fallbackSrc}"
                                            alt="${location.displayAddress}"
                                            loading="eager"
                                            fetchpriority="high"
                                        />
                                    </div>
                                </a>
                            </div>
                        `;
                    }

                    return `
                        <div class="slider-banner-image keen-slider__slide">
                            <a href="${location.propertyUrl}">
                                <div class="slider-banner-image-img">
                                    <img 
                                        width="317"
                                        height="200"
                                        src="${fallbackSrc}"
                                        alt="${location.displayAddress}"
                                        loading="lazy"
                                        fetchpriority="low"
                                    />
                                </div>
                            </a>
                        </div>
                    `;
                }).join('');
    
                imageContainer = `
                    <section class="search-result-property__gallery" data-dc-search-result-gallery >
                        <div class="property-carousel">
                            <div class="property-carousel--main-wrapper">
                                <div class="property-carousel--info-banners">
                                    ${virtualTours}
                                    ${propertyStatus}
                                    ${IsShortLet}
                                </div>
                                <div class="property-carousel--slider-counter"></div>
                                <div class="property-carousel--main keen-slider">
                                    ${gallery}
                                </div>
                            </div>
                        </div>
                    </section>
                `;
    
            }
    
        } else {
    
            gallery = `
                <img 
                    width="317"
                    alt="${location.displayAddress}" 
                    src="${mainImage}?width=320"
                    srcset="${location.mainImageSrcSet}"
                    loading="eager"
                    fetchpriority="high"
                />
            `;
    
            imageContainer = `
                <section class="search-result-property__gallery">
                    <div class="property-carousel--main">
                        <div class="slider-banner-image">
                            <div class="slider-banner-image-img">
                                ${gallery}
                            </div>
                        </div>
                    </div>
                </section>
            `;
    
        }                
    
        if (bedrooms !== 0 || propertyType !== '') {
            if (bedrooms !== 0) {
                propertyTypeString = `<div class="search-result-property__info"><span class="search-result-property__info-bed">${bedrooms} bedroom</span> <span class="search-result-property__info-type">${propertyType}</span></div>`;
            } else {
                if (propertyType !== 'House') {
                    propertyTypeString = `<div class="search-result-property__info"><span class="search-result-property__info-type">${propertyType}</span></div>`;
                }
            }
        }
    
        let propertySpecs = '';
        if (bedrooms !== null || bathrooms !== null || receptionRooms !== null) {
            propertySpecs = `
                <div class="search-result-property__specs">
                    <ul class="specs">
                        ${bedrooms !== 0 ? `<li class="specs__item"><svg width="21" height="14" class="specs__icon" role="img"><title>Bedrooms</title><use xlink:href="#icon-bed"></use></svg><span class="specs__text">${bedrooms}</span></li>` : ''}
                        ${bathrooms !== 0 ? `<li class="specs__item"><svg width="20" height="19" class="specs__icon" role="img"><title>Bathrooms</title><use xlink:href="#icon-bath"></use></svg><span class="specs__text">${bathrooms}</span></li>` : ''}
                        ${receptionRooms !== 0 ? `<li class="specs__item"><svg width="20" height="14" class="specs__icon" role="img"><title>Reception rooms</title><use xlink:href="#icon-sofa"></use></svg><span class="specs__text">${receptionRooms}</span></li>` : ''}
                    </ul>
                </div>
            `;
        }

        const office = location.office !== null ? location.office : '';
    
        if (office !== '') {
            officeNumber = location.propertyChannel === 'Sales' ? location.office.salesOfficeNumber : location.office.lettingsOfficeNumber;
        }
        
        const estateAgents = `<div class="search-result-property__footer-estate-agents"><a href="${officeUrl}">${officeName} Estate Agents</a></div>`;
    
        // Expanded version for mobile view - to be shown when user taps on the collapsed version
        const cardContents = `
            <article class="search-result-property">
                <div class="search-result-property__inner">
                    <main class="search-result-property__content">
                        <div class="search-result-property__content--left">
                            ${imageContainer}
                        </div>
                        <div class="search-result-property__content--right">
                            <a class="search-result-property__content-card-link" href="${location.propertyUrl}">
                                ${propertyTypeString}
                                <h2 class="search-result-property__title">
                                    ${location.displayAddress}
                                </h2>                                
                                <div class="search-result-property__details">
                                    <h3 class="search-result-property__price">
                                        ${location.formattedPrice}
                                    </h3>
                                    ${propertySpecs}
                                </div>
                            </a>
                            <div class="search-result-property__content-footer">
                                ${estateAgents}
                                <div class="search-result-property__footer-reference">
                                    Ref: ${location.externalID}
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer class="search-result-property__footer">
                        <div class="search-result-property__footer-right">
                            <div class="search-result-property__footer-contact">
                                <a href="tel:${officeNumber}" class="search-result-property__footer-contact-number">
                                    <svg class="icon icon--phone" width="20" height="20" focusable="false"><use xlink:href="#icon-phone-new"></use></svg>
                                    <span class="search-result-property__footer-icon-text desktop">${officeNumber}</span>
                                    <span class="search-result-property__footer-icon-text mobile">Call</span>
                                </a>
                            </div>
                            <div class="search-result-property__footer-email">
                                <a href="${location.propertyUrl}#contact_form" class="search-result-property__email">
                                    <svg class="icon icon--email" width="20" height="20" focusable="false"><use xlink:href="#icon-email-new"></use></svg>
                                    <span class="search-result-property__footer-icon-text">Email</span>
                                </a>
                            </div>
                            <div class="search-result-property__footer-save">
                                <button class="search-result-property__save iconbtn" type="button" data-dc-save-property-btn='{
                                    "id": "${location.externalID}",
                                    "image": "${location.mainImageUrl}",
                                    "name": "${location.displayAddress}",
                                    "price": "${location.formattedPrice}",
                                    "urlToDetails": "${location.propertyUrl}"
                                }'>
                                    <svg class="icon icon--heart" width="20" height="20" focusable="false">
                                        <use xlink:href="#icon-heart-new"></use>
                                    </svg>
                                    <span class="search-result-property__footer-icon-text">
                                        Save
                                    </span>
                                </button>
                            </div>
                        </div>
                    </footer>
                </div>
            </article>
        `;
    
        // Condensed version for initial mobile view (collapsed)
        const mobileCollapsed = `
            <div id="marker-${location.externalID}">
                <div class="property-map-popup mobile mobile_small show">
                    <div class="map-popup__inner">
                        <div class="map-popup__content search-result-popup">
                            <div class="map-popup__content-top">
                                <div class="map-popup__content-left">                    
                                    <a class="search-result-popup__img" href="${location.propertyUrl}">
                                        <img  
                                            width="132"
                                            src="${mainImage}?width=320"
                                            alt="${location.displayAddress}"
                                            loading="eager"
                                            fetchpriority="high"
                                        />
                                    </a>
                                </div>
                                <div class="map-popup__content-right">
                                    <div class="map-popup__content-right--type">
                                        ${bedrooms} bedroom house
                                    </div>
                                    <div class="search-result-popup__title" href="${location.propertyUrl}">
                                        ${location.displayAddress}
                                    </div>
                                    <div class="search-result-popup__price">
                                        ${location.formattedPrice}
                                        ${location.tenure !== null ? `<span class="search-result-popup__price-info">${location.tenure}</span>` : ''}                                        
                                    </div>
                                </div>
                            </div>                            
                            <div class="map-popup__content-bottom">
                                <button class="map-expand-trigger" data-location-id="${location.externalID}">${seeMoreCTAText}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="property-map-popup mobile mobile_expanded hide">
                    <button class="map-popup-close" data-location-id="${location.externalID}">Close</button>
                    ${cardContents}
                </div>
            </div>
        `;
        
        // Desktop version
        const desktop = `
        <div id="marker-${location.externalID}">
            <div class="property-map-popup desktop">
                <button class="map-popup-close" data-location-id="${location.externalID}">Close</button>
                ${cardContents}                
            </div>
        </div>`;
    
        if (window.innerWidth <= 768) {
            return mobileCollapsed;
        } else {
            return desktop;
        }
    
    };
}

// Function to re-initialise Keen Slider Carousel
function reInitKeenSlider(container) {
    const mainSlider = container.querySelector('.property-carousel--main');
    const slideCounter = container.querySelector('.property-carousel--slider-counter');

    if (!mainSlider) {
        return;
    }
    // Initialize the main slider
    var slider = new KeenSlider(mainSlider, {
        initial: 0,
        loop: true,
        spacing: 0,
        controls: true,
        renderMode: 'performance',
        mode: 'snap',
        slideChanged: () => { 
            if (slideCounter) {
                updateSlideCounter(slider, slideCounter)
            }
        },
        created: (instance) => {
            if (slideCounter) {
                updateSlideCounter(instance, slideCounter);
            }
        }
    });

    // Adding arrow navigation
    addArrowNavigation(slider, mainSlider);
        
    // Function to update slide counter            
    function updateSlideCounter(slider, counterElement) {
        // Adjust for looping by using modulus operator
        let currentSlide = slider.track.details.abs % slider.slides.length + 1;
        const totalSlides = slider.slides.length;
        if (currentSlide < 1) {
            currentSlide += totalSlides;
        }
        counterElement.textContent = `${currentSlide}/${totalSlides}`;
    }


    // Function to add arrow navigation
    function addArrowNavigation(slider, container) {
        // Check if arrows already exist and remove them if they do
        const existingArrows = container.querySelectorAll('.arrow');
        existingArrows.forEach(arrow => arrow.remove());

        const arrowLeft = document.createElement('button');
        arrowLeft.innerText = '<';
        arrowLeft.classList.add('arrow', 'arrow--left');
        arrowLeft.addEventListener('click', () => slider.prev());

        const arrowRight = document.createElement('button');
        arrowRight.innerText = '>';
        arrowRight.classList.add('arrow', 'arrow--right');
        arrowRight.addEventListener('click', () => slider.next());

        container.appendChild(arrowLeft);
        container.appendChild(arrowRight);
    }

}

function getPopupClass() {
    return class GooglePopup extends google.maps.OverlayView {
        constructor(position, contentElement, options = {}) {
            super();
            this.position = position;
            this._createAnchor();
            this.setContent(contentElement);
        }

        _createAnchor() {
            this.anchor = document.createElement('div');
            this.anchor.style.cursor = 'auto';
            this.anchor.style.height = '0';
            this.anchor.style.position = 'absolute';
        }

        setContent(element) {
            this.anchor.innerHTML = '';
            this.anchor.insertAdjacentElement('beforeend', element);
            // stop events from from bubbling
            google.maps.OverlayView.preventMapHitsAndGesturesFrom(element);
        }

        remove() {
            this._removeFromDOM();
        }

        _removeFromDOM() {
            if (this.anchor.parentElement) {
                this.anchor.parentElement.removeChild(this.anchor);
            }
        }

        onAdd() {
            /** Called when the popup is added to the map */
            this.getPanes().floatPane.appendChild(this.anchor);
        }

        onRemove() {
            /** Called when the popup is removed from the map. */
            this._removeFromDOM();
        }

        draw() {
            /** Called when the popup needs to draw itself. */
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

            if (display === 'block') {
                this.anchor.style.left = divPosition.x + 'px';
                this.anchor.style.top = divPosition.y + 'px';
            }
            if (this.anchor.style.display !== display) {
                this.anchor.style.display = display;
            }
        }
    }
}