import analyticsService from 'general/js/analytics-service';

const CATEGORY = 'category';
const ACTION = 'action';
const LABEL = 'label';

export default class GtmHelper {
    static send(e, options) {
        analyticsService.sendEvent(
            this._getMethod(CATEGORY, options.category, e),
            this._getMethod(ACTION, options.action, e),
            this._getMethod(LABEL, options.label, e),
        );
    }

    static _getMethod(option, value, e) {
        const target = e && e.target ? e.target : null;
        if (option === CATEGORY && !value) return this._getValue('href', e);
        if (option === ACTION && !value) return 'Click';
        if (option === LABEL && !value) return target ? target.textContent.trim() : this._getValue('page');
        return this._getValue(value, target);
    }

    static _getValue(value, target) {
        switch (value) {
            case 'page':
                return document.title || window.location.href;
            case 'url':
                return window.location.href;
            case 'href':
                return target && target.hasAttribute('href') ? target.getAttribute('href') :
                    document.title || window.location.href;
            default:
                return value;
        }
    }
}
