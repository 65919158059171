const DEFAULT_CLASS = 'spinner';

export default class ElementSpinner {
    constructor(element, options = {}) {
        this.element = element;
        this.inited = false;
        this.options = {
            isFaded: false,
            mobileSmall: false,
            modifiers: null,
            ...options
        };
    }

    init() {
        this.spinnerElement = document.createElement('div');
        this.spinnerElement.className = this.getClassName();
        this.element.insertAdjacentElement('beforeend', this.spinnerElement);
        this.inited = true;
    }

    getClassName() {
        return `${DEFAULT_CLASS} ${this.options.isFaded ? 'is-faded' : ''} ${
            this.options.mobileSmall ? 'spinner--mobile-small' : ''
        }`;
    }

    show() {
        if (!this.inited) {
            this.init();
        }

        this.spinnerElement.classList.add('is-visible');
        if (this.options.modifiers) {
            this.options.modifiers.forEach((modifier) => {
                this.spinnerElement.classList.add(`${DEFAULT_CLASS}--${modifier}`);
            });
        }
    }

    hide() {
        if (this.inited) {
            this.spinnerElement.classList.remove('is-visible');
            if (this.options.modifiers) {
                this.options.modifiers.forEach((modifier) => {
                    this.spinnerElement.classList.remove(`${DEFAULT_CLASS}--${modifier}`);
                });
            }
        }
    }
}
