import cheResult from './che.json';
import lonResult from './lon.json';

export default function (mock) {
    mock.onGet('/api/locations-near').reply((config) => {
        let result = [];

        console.warn(`${config.url} was mocked`);

        const stringLat = parseFloat(config.params.latitude).toString();
        if (stringLat.startsWith('51')) {
            result = lonResult;
        } else if (stringLat.startsWith('55')) {
            result = cheResult;
        } else {
            result = [];
        }

        return [200, result];
    });
}
