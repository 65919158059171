import { deviceObserver } from '@deleteagency/device-observer';
import throttle from 'lodash/throttle';
import ScrollbarHandler from './scrollbar-handler.js';
import constants from './constants';

const DEVICE_MAX = 'max';
export const DEVICE_TABLET = 'tablet';
export const DEVICE_MOBILE = 'mobile';
export const DEVICE_DESKTOP = 'desktop';

class App {
    constructor() {
        this.config = window.appConfig || {};
    }

    init() {
        deviceObserver.init(
            {
                [DEVICE_MAX]: Infinity,
                [DEVICE_TABLET]: constants.VIEWPORT_WIDTH_TABLET,
                [DEVICE_MOBILE]: constants.VIEWPORT_WIDTH_MOBILE,
                [DEVICE_DESKTOP]: constants.VIEWPORT_WIDTH_DESKTOP
            },
            { mobileFirst: false }
        );

        this.setViewportHeight();
        this.setScrollbarSize();

        const resizeViewportHandler = throttle(this.setViewportHeight, 500);
        const resizeScrollbarHandler = throttle(this.setScrollbarSize, 500);
        window.addEventListener('resize', resizeViewportHandler);
        window.addEventListener('resize', resizeScrollbarHandler);
    }

    setViewportHeight() {
        const height = window.innerHeight;
        document.body.style.setProperty('--vh', `${height}px`);
    }

    setScrollbarSize() {
        const scrollbarSize = ScrollbarHandler.getScrollbarSize();
        document.body.style.setProperty('--scrollbar', `${scrollbarSize}px`);
    }

    getConfig(property, defaultValue = undefined) {
        return property in this.config ? this.config[property] : defaultValue;
    }
}

const instance = new App();
export default instance;
