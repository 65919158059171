import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import locationsMock from './mocks/locations';
import locationsNearMock from './mocks/locations-near';

const instance = axios.create({
    timeout: 30000,
    withCredentials: true
});

export const mock = new MockAdapter(instance, { delayResponse: 500 });
locationsNearMock(mock);
mock.onAny().passThrough();

export default instance;
