class IntersectionHelper {
    constructor() {
        // take a fixed header into consideration
        this.defaultOptions = {
            rootMargin: '-100px 0px 0px 0px'
        };
    }

    enterViewportOnce(element, callback, options = {}) {
        options = { ...this.defaultOptions, ...options };
        const observer = new IntersectionObserver((changes, observer) => {
            const change = changes[0];
            if (change.isIntersecting) {
                observer.disconnect();
                callback(change);
            }
        }, options);
        observer.observe(element);
        return observer;
    }

    enterViewport(element, callback, options = {}) {
        options = { ...this.defaultOptions, ...options };
        const observer = new IntersectionObserver((changes) => {
            const change = changes[0];
            if (change.isIntersecting) {
                callback(change);
            }
        }, options);
        observer.observe(element);
        return observer;
    }
}

const instance = new IntersectionHelper();
export default instance;
