import { DcBaseComponent } from "@deleteagency/dc";
import $ from "jquery";

export class TabbedSearch extends DcBaseComponent {
    static getNamespace() {
        return 'tabbed';
    }

    onInit() {
        if (!this.refs.btns || !this.refs.contentElements) {
            return;
        }

        this.tabButtons = Object.values(this.refs.btns);
        this.tabContents = Object.values(this.refs.contentElements);

        setTimeout(() => this.initTabs(), 10);

        this.setupSectionHandlers('#searchBuy');
        this.setupSectionHandlers('#searchRent');
        this.setupSectionHandlers('#searchNearest');

        this._inputClear();

        ['searchBuy', 'searchRent', 'searchNearest'].forEach(sectionId => {
            this.observeMutations(this.disableSearchCheck, $(`#${sectionId}`)[0]);
            this.observeMutations(this.copySearchSuggestionToAllInputs, $(`#${sectionId}`)[0]);
        });

        const submitBtn = $('.quick-search__input-wrapper button[type="submit"]');
        const searchInput = $('.updated-quick-search__input-text');        
        submitBtn.on('click', () => {
            this.setLocalStorageToSearchValue(searchInput);
        });

        searchInput.on('keydown', () => {
            searchInput.attr('data-location', '');
        });

        searchInput.on('keyup', () => {
            submitBtn.prop('disabled', !this.isValidInput(searchInput.val()));
        });
    }

    isValidInput(inputVal) {
        return inputVal.trim().length >= 3;
    }

    setupSectionHandlers(sectionSelector) {
        const section = $(sectionSelector);
        const searchInput = section.find('.updated-quick-search__input-text');
    
        // Delegate click events to current and future .popular-locations__list-item elements within the section
        section.on('click', '.popular-locations__list-item', function() {
            searchInput.val($(this).text());
            
            // After setting the input value, determine if the submit buttons should be enabled or disabled
            ['searchBuy', 'searchRent', 'searchNearest'].forEach(sectionId => {
                const btn = $(`#${sectionId}`).find('.quick-search__input-wrapper button[type="submit"]');
                btn.attr('disabled', searchInput.val().length < 3 ? 'disabled' : false);
            });
        });
    }
    

    initTabs() {
        const getTabName = (str) => str.split('[')[1].split(']')[0];
        this.tabButtons.forEach(btn => {
            const tabName = getTabName(btn.dataset.dcTabbedRef);
            btn.addEventListener('click', () => this.openTab(tabName));
        });
    }

    _inputClear() {
        const searchInput = $('.updated-quick-search__input-text');
        const submitBtn = $('.quick-search__input-wrapper button[type="submit"]');
        $(document).on('click', '.predictive__clear-btn', function () {
            searchInput.each(function () {
                $(this).val('');
                $(this).attr('data-location', '');
            });
            submitBtn.each(function () {
                $(this).attr('disabled', 'disabled');            
            });
        });
    }

    updateActive(elements, activeName, refType) {
        elements.forEach(el => {
            const isActive = this.refs[refType][activeName] === el;
            el.classList.toggle('active', isActive);
        });
    }

    openTab(tabName) {
        this.updateActive(this.tabContents, tabName, 'contentElements');
        this.updateActive(this.tabButtons, tabName, 'btns');
    }

    disableSearchCheck() {
        const searchInputVal = $('.updated-quick-search__input-text').val();
        const submitBtn = $('.quick-search__input-wrapper button[type="submit"]');
        submitBtn.attr('disabled', searchInputVal.length < 3 ? 'disabled' : false);
    }

    observeMutations(callback, targetNode = document.body) {
        const observer = new MutationObserver(mutationsList => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    callback();
                }
            });
        });

        observer.observe(targetNode, { childList: true, subtree: true });
    }

    copySearchSuggestionToAllInputs() {
        const search = $('.updated-quick-search__input-text')
        const suggestionOptions = $('.react-autosuggest__suggestion');

        suggestionOptions.off('click').on('click', function () {
            const value = $(this).text();
            search.val(value).trigger('change');            
            search.attr('data-location', 'valid');
            $('.quick-search__input-wrapper button[type="submit"]').attr('disabled', value.length < 3 ? 'disabled' : false);
            $('.popular-locations').removeClass('active');
        });
    }
    
    setLocalStorageToSearchValue(inputElement) {
        const searchInput = inputElement.val() || '';
        const storedSearches = JSON.parse(localStorage.getItem('winkworth_recently_searched_locations')) || [];
        const searchValueWithTimestamp = {
            location: searchInput,
            timestamp: Date.now()
        };
        let found = false;
        const updatedSearchesWithTimestamp = storedSearches.map(search => {
            if (search.location === searchInput) {
                found = true;
                return searchValueWithTimestamp;
            }
            return search;
        });

        if (!found) {
            updatedSearchesWithTimestamp.push(searchValueWithTimestamp);
        }

        if (inputElement.attr('data-location') === 'valid') {
            localStorage.setItem('winkworth_recently_searched_locations', JSON.stringify(updatedSearchesWithTimestamp));
        }

    }
}
