import { dcFactory } from '@deleteagency/dc';
import BaseMarker from './js/base-marker';
import onMapsReady from './js/load-google-maps-script';
import MapComponent from './js/map.component';
import OutsidePropertyMarker from './js/outside-property-marker';
import StreetViewComponent from './js/street-view.component';
import './scss/index.scss';

dcFactory.register(MapComponent);
dcFactory.register(StreetViewComponent);

export { onMapsReady, BaseMarker, OutsidePropertyMarker };
