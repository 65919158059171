const EVENT_SUBNAV_TAB_TRIGGER = 'subnav-tab:trigger';
const EVENT_VIDEO_PAUSE = 'video:pause';
const EVENT_TAB_CHANGE = 'tab:change';
const REFRESH_SCROLL_TRIGGER_EVENT = 'refresh_scroll_trigger_event';
const TOGGLE_SCROLL_TRIGGER_EVENT = 'toggle_scroll_trigger_event';
const ADDITIONAL_SLIDERS_LOADED_EVENT = 'additionalSlidersEvent';

const MAP_POPUP_OPENED = 'mapPopupOpened';

export default {
    EVENT_SUBNAV_TAB_TRIGGER,
    EVENT_VIDEO_PAUSE,
    EVENT_TAB_CHANGE,
    REFRESH_SCROLL_TRIGGER_EVENT,
    TOGGLE_SCROLL_TRIGGER_EVENT,
    ADDITIONAL_SLIDERS_LOADED_EVENT,
    MAP_POPUP_OPENED
};
