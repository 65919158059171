import { AxiosSingular } from '@deleteagency/axios-singular';
import eventBus from 'general/js/events/bus';
import axios from 'axios';
import app from '../app';
import api from '../api';

export const EVENT_GOT_USER_INFO = 'eventGotUserInfo';

class UserService {
    constructor(userProfileApiUrl) {
        this._userProfileApiUrl = userProfileApiUrl;
        this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);
        this._fetchUser();
        this.user = null;
    }

    _fetchUser() {
        this.axiosSingular.cancel();
        this.axiosSingular.get(this._getUserProfileUrl()).then((response) => {
            const user = response.data || null;
            if (this.user !== user) {
                eventBus.emit(EVENT_GOT_USER_INFO, user);
                this.user = user;
            }
        });
    }

    refetchUser() {
        this._fetchUser();
    }

    _getUserProfileUrl() {
        return `${this._userProfileApiUrl}`;
    }
}

const instance = new UserService(app.getConfig('userProfileApiUrl'));
export default instance;
