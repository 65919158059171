import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';
import { unescape, escape } from 'lodash';
import api from '../../../general/js/api';

export default class CustomAcitivityHelper {
    static addItemsToValueString(valueString, items) {
        const valueXML = new DOMParser().parseFromString(unescape(valueString), "text/xml");

        for (const property in items) {
            const el = valueXML.createElement(property);
            const textEl = valueXML.createTextNode(items[property]);
            el.appendChild(textEl);
            const valueTag = valueXML.getElementsByTagName('Value')[0];

            if (valueTag) valueTag.appendChild(el);
        }

        const newValueString = new XMLSerializer().serializeToString(valueXML.documentElement);

        return escape(newValueString);
    }

    static send({ activityTitle, activityType, detailId, itemId, valueString }) {
        const url = window.location.href;
        const urlReferrer = document.referrer;
        const queryString = new URLSearchParams({
            activityTitle,
            activityType,
            detailId,
            itemId,
            valueString,
            url,
            urlReferrer
        }).toString();

        this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);

        this.axiosSingular.post(`/api/activity/customactivity/submit?${queryString}`).then(
            () => {},
            (error) => {
                if (!axios.isCancel(error)) {
                    // eslint-disable-next-line no-console
                    console.error('Actual error!', error);
                }
            }
        );
    }
}
